<template>
  <div class="wrap-event-board">
    <div ref="content" class="content">
      <div
        ref="eventcard"
        v-for="(event, index) in historyEventList"
        :key="event.id"
      >
        <EventCard :event="event" :index="index" />
      </div>
    </div>
    <div class="bottom">
      <Pagination
        :currentPage="historyCurrentPage"
        :total="historyTotal"
        :pageSize="historyPageSize"
        mode="single"
        @getPageData="getPageEvents"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'EventBoard',
  components: {
    EventCard: () =>
      import('@/components/History/HistoryContent/EventBoard/EventCard.vue'),
    Pagination: () => import('@/components/Base/Pagination.vue')
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('history', [
      'historyTotal',
      'historyEventsCount',
      'historyPageSize',
      'historyCurrentPage',
      'historyEventList',
      'focusEventIndex'
    ])
  },
  watch: {
    focusEventIndex() {
      if (this.focusEventIndex === -1) return
      setTimeout(() => {
        /* 移動前一筆資料置頂 */
        let previousIndex =
          this.focusEventIndex >= 1 ? this.focusEventIndex - 1 : 0
        this.$refs.eventcard[previousIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }, 200)
    }
  },
  methods: {
    ...mapActions('history', ['searchEvents']),
    async getPageEvents(payload) {
      if (payload === 0) {
        this.$notify({
          title: this.$t('search_hint') /* 提示 */,
          message: '已在第一頁',
          type: 'warning'
        })
        return
      }

      // if (this.historyEventsCount < (payload - 1) * this.historyPageSize) {
      //   this.$notify({
      //     title: this.$t('search_hint')/* 提示 */,
      //     message: '無下一頁資料',
      //     type: 'warning'
      //   })
      //   return
      // }

      await this.searchEvents({ page: payload })
      setTimeout(() => {
        /* 移動前一筆資料置頂 */
        let previousIndex =
          this.focusEventIndex >= 1 ? this.focusEventIndex - 1 : 0
        this.$refs.eventcard[previousIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-event-board {
  background: #282942;
  border-radius: 7px;
  width: 480px;
  height: 100%;
  position: relative;
  padding: 12px 5px 15px 15px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 7px;
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  padding-right: 10px;
  margin-top: 10px;
}

.page-info {
  padding-right: 4px;
}

.page-info span:last-child {
  color: #ffe99f;
}
</style>